import React, { useState } from 'react';
export const steps = [1, 2, 3, 4, 5, 6];
export interface ProgressBarProps {
  id: number;
  setId: React.Dispatch<React.SetStateAction<number>>;
}
function ProgressBar({ id }: ProgressBarProps) {
  const step = [
    { title: 'Step 1', completed: false },
    { title: 'Step 2', completed: false },
    { title: 'Step 3', completed: false }
  ];
  return (
    <div className="flex justify-center items-center relative py-9 lg:py-14">
      {steps.map((number, i) => (
        <div className="flex flex-col" key={number}>
          <div className="flex items-center">
            <div
              className={`rounded-full flex justify-center items-center w-6 h-6 md:text-xs transition duration-700 ${
                id - 1 > i
                  ? 'border-brand text-white bg-brand border-2 '
                  : `${
                      i === id - 1
                        ? 'border-brand border-2  text-brand bg-gray-200'
                        : 'text-gray-500 border-gray-300 border-2 '
                    }`
              }  z-10`}
            >
              {number}
            </div>
            {i !== steps.length - 1 && (
              <div
                className={` ${
                  id - 1 > i ? 'bg-brand' : 'bg-gray-300'
                } w-7 md:w-16 lg:w-20  h-[2px] `}
              ></div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default ProgressBar;

interface Step {
  title: string;
  completed: boolean;
}

export const ProgressBarWithSteps: React.FC<{ steps: Step[] }> = ({ steps }) => {
  const [currentStep, setCurrentStep] = useState(0);

  const handleClick = (index: number) => {
    setCurrentStep(index);
  };

  const progress = ((currentStep + 1) / steps.length) * 100;

  return (
    <div>
      <div className="progress">
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: `${progress}%` }}
          aria-valuenow={progress}
          aria-valuemin={0}
          aria-valuemax={100}
        ></div>
      </div>
      <div className="steps">
        {steps.map((step, index) => (
          <div
            key={index}
            className={`step ${index === currentStep ? 'active' : ''} ${
              step.completed ? 'completed' : ''
            }`}
            onClick={() => handleClick(index)}
          >
            {step.title}
          </div>
        ))}
      </div>
    </div>
  );
};
