interface EmailValidationProps {
  email: string;
  setEmailError: React.Dispatch<React.SetStateAction<string>>;
}
export const keyDown = (e: any) => {
  if (e?.key === "Enter") {
    const form = e.target.form;
    const index = [...form].indexOf(e.target);
    form[index + 1]?.focus();
    e.preventDefault();
  }
};
export const emailRegex: RegExp =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
function EmailValidation({ email, setEmailError }: EmailValidationProps) {
  if (!email) {
    return setEmailError('Email cannot be empty');
  }
  if (!email.match(emailRegex)) {
    return setEmailError('Email Syntax is not Correct');
  } else {
    return setEmailError('');
  }
}

export default EmailValidation;
