interface PhoneNoValidationProps {
  setPhoneNoError: React.Dispatch<React.SetStateAction<string>>;
  phoneNo: string;
}

export function PhoneNoValidation({ setPhoneNoError, phoneNo }: PhoneNoValidationProps) {
  // let obj = {} as FormValues;
  if (!phoneNo) {
    setPhoneNoError('Enter a Valid number');
  } else if (phoneNo?.length < 14) {
    return setPhoneNoError('Phone Syntax is not Correct');
  }
}
export function phoneFormat(n: any) {
  let input = n.slice(2);
  var size = input.length;
  if (size > 0) {
    input = '(' + input;
  }
  if (size > 3) {
    input = input.slice(0, 4) + ') ' + input.slice(4, 11);
  }
  if (size > 6) {
    input = input.slice(0, 9) + '-' + input.slice(9);
  }
  return input;
}
export function formatPhoneNumber(phoneNumber: any) {
  const cleanNum = phoneNumber.toString().replace(/\D/g, '');
  const match = cleanNum.match(/^(\d{3})(\d{0,3})(\d{0,4})$/);

  if ((match && match[2]) === '') {
    return match[1];
  }

  if ((match && match[3]) === '') {
    return `(${match[1]}) ${match[2]}`;
  }

  if (match) {
    // return '(' + match[1] + ') ' + (match[2] ? match[2] + '-' : '') + match[3];
    return `(${match[1]}) ${match[2] ? `${match[2]}-` : ''}${match[3]}`;
  }
  return cleanNum;
}