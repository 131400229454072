import { Variants } from 'framer-motion';

export const textanimation: Variants = {
  offscreen: {
    opacity: 0
  },
  onscreen: {
    opacity: 1,
    y: [50, 0],
    transition: { duration: 0.5 },
  }
};

export const cardanimation: Variants = {
  offscreen: {
    opacity: 0,
    x: -100
  },
  onscreen: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.5
    }
  }
};
