import React from 'react';

interface checkValueProps {
  title: string;
  checked: boolean;
  onClick?: () => void;
  defaultValue: string;
  id: string;
}

function CheckBoxes({ title, onClick, checked, defaultValue, id }: checkValueProps) {
  return (
    <div
      className={`flex items-start rounded-lg h-7 w-full flex-wrap justify-start  mb-2 ${
        checked === true && ''
      } font-medium md:font-semibold`}
      onClick={onClick}
    >
      <div id={id} className={`flex items-center text-neutral-800 gap-3 pt-2 w-full`}>
        <input
          id={id}
          type="radio"
          className="w-3 h-3  accent-brand"
          value={defaultValue}
          name={defaultValue}
          checked={checked}
          onChange={() => ''}
        />
        <label
          htmlFor={title}
          id={id}
          className={`ml-3 hover:text-black text-sm md:text-xl md:leading-[50px]`}
        >
          {title}
        </label>
      </div>
    </div>
  );
}

export default CheckBoxes;
