export const borrowAmountRange = [
  { id: 'page10_btn_borrow_below_$100,000', name: 'Below $100,000' },
  { id: 'page10_btn_borrow_$100,000_$300,000', name: '$100,000 - $300,000' },
  { id: 'page10_btn_borrow_$300,000_$600,000', name: '$300,000 - $600,000' },
  { id: 'page10_btn_borrow_$600,000+', name: '$600,000+' }
];
export const homeType = [
  { id: 'page10_btn_type_house', name: 'House' },
  { id: 'page10_btn_type_townhouse', name: 'Townhouse' },
  { id: 'page10_btn_type_mobile_home', name: 'Mobile Home' },
  { id: 'page10_btn_type_rental_property', name: 'Rental Property' }
];
// ApplyNow: estimated Amount
export const estimateAmountRange = [
  { id: 'page10_btn_property_$100,000_$300,000', name: '$100,000 - $300,000' },
  { id: 'page10_btn_property_$300,000_$600,000', name: '$300,000 - $600,000' },
  { id: 'page10_btn_property_$600,000 - $900,000', name: '$600,000 - $900,000' },
  { id: 'page10_btn_property_$900,000+', name: '$900,000+' }
];
export const mortgageAmountRange = [
  { id: 'page10_btn_mortgage_$100,000_$300,000', name: '$100,000 - $300,000' },
  { id: 'page10_btn_mortgage_$300,000_$600,000', name: '$300,000 - $600,000' },
  { id: 'page10_btn_mortgage_$600,000 - $900,000', name: '$600,000 - $900,000' },
  { id: 'page10_btn_mortgage_$900,000+', name: '$900,000+' }
];
export const liveProperty = [
  { id: 'page10_btn_type_house', name: 'House' },
  { id: 'page10_btn_type_townhouse', name: 'Townhouse' },
  { id: 'page10_btn_type_mobile_home', name: 'Mobile Home' },
  { id: 'page10_btn_type_rental_property', name: 'Rental Property' }
];
export const interestedIn = [
  { id: 'page10_btn_Home_equity_loan', name: 'Home Equity Loan' },
  { id: 'page10_btn_Home_equity_line(HELOC)', name: 'Home equity line (HELOC)' },
  { id: 'page10_btn_Renew_my_mortgage', name: 'Renew my mortgage' }
];
