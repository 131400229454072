import { BasicInputProps, InputBasicStyle } from './BasicInput';
import React from 'react';

function PhoneNo({
  onchange,
  placeHolder,
  Error,
  value,
  maxLength,
  focus,
  className,
  type
}: BasicInputProps) {
  return (
    <input
      onChange={onchange}
      maxLength={maxLength}
      value={value}
      type="tel"
      autoFocus={focus}
      placeholder={placeHolder}
      className={`outline-none w-full rounded-full px-8 font-sans text-base pl-4 md:px-16 py-2`}
    />
  );
}

export default PhoneNo;
