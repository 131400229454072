import React from 'react';
import { textanimation } from '../../Animation';
import { motion } from 'framer-motion';
interface headingProps {
  children: JSX.Element;
}
interface props {
  id: number;
}
function Heading({ id }: props) {
  return (
    <div className={`my-4 md:mt-7  ${id == 6 ? 'md:mb-28' : 'md:mb-32'}`}>
      {id == 1 && (
        <HeadingQuestion>
          <div className="w-11/12 md:w-10/12 xl:w-[1200px] mx-auto">
            <span className="text-brand"> Calculate </span>how much home equity you can
            <span className="text-brand"> access now!</span>
          </div>
        </HeadingQuestion>
      )}
      {id == 2 && (
        <HeadingQuestion>
          <div className="w-10/12 md:w-8/12 xl:w-[916px] mx-auto">
            Clients
            <span className="text-brand"> SAVE </span> over
            <span className="text-brand"> $15,000 </span> a year with our proven strategies.
          </div>
        </HeadingQuestion>
      )}{' '}
      {id == 3 && (
        <HeadingQuestion>
          <div className="w-10/12 mx-auto">
            We've saved clients over <br />
            <span className="text-brand"> 8,000,000 </span> and counting.
          </div>
        </HeadingQuestion>
      )}{' '}
      {id == 4 && (
        <HeadingQuestion>
          <div className="w-10/12 md:w-8/12 mx-auto">
            Get a free
            <span className="text-brand"> home equity </span> report.
          </div>
        </HeadingQuestion>
      )}{' '}
      {id == 5 && (
        <HeadingQuestion>
          <div className="w-10/12 md:w-8/12 2xl:w-9/12 mx-auto">
            See real <span className="text-brand"> mortgage solutions </span>before you commit.
          </div>
        </HeadingQuestion>
      )}{' '}
      {id == 6 && (
        <HeadingQuestion>
          <>
            <div className="w-10/12 md:w-9/12 xl:w-[1046px] mx-auto">
              We can’t wait to <span className="text-brand"> get started </span>on your reports!
            </div>
            <p className="text-xl font-medium mt-5 md:mt-10 mx-2 md:mx-0">
              We also hate SPAM, your data will be kept confidential.
            </p>
          </>
        </HeadingQuestion>
      )}
    </div>
  );
}
export default Heading;

function HeadingQuestion({ children }: headingProps) {
  return (
    <div>
      <motion.div
        variants={textanimation}
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
      >
        <p className="font-raleway mx-2 sm:mx-0 text-3xl md:text-4xl lg:text-5xl xl:text-7xl text-center font-black lg:leading-[70px]">
          {children}
        </p>
      </motion.div>
    </div>
  );
}
