import { Variants } from 'framer-motion';
export const textanimation: Variants = {
  offscreen: {
    opacity: 0
  },
  onscreen: {
    opacity: 1,
    y: [50, 0],
    transition: { duration: 0.4 }
  }
};

export const container = {
  offscreen: { opacity: 1, scale: 0 },
  onscreen: {
    opacity: 1,
    scale: 1,
    dumping: 2,
    transition: {
      delayChildren: 0.2,
      duration: 0.2,
      staggerChildren: 0.2
    }
  }
};

export const item = {
  offscreen: { y: 20, opacity: 0 },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      ease: 'linear',
      duration: 0.1
    }
  }
};

export const wrapperVariants = {
  initial: {
    clipPath: 'circle(100px at 90% -10%)',
    transition: {
      type: 'spring',
      ease: 'easeInOut',
      duration: 0.4
    }
  },

  animate: {
    clipPath: 'circle(1000px at 80% 20%)',
    transition: { duration: 0.6, ease: 'easeInOut' }
  },
  exit: {
    ease: 'easeout',
    transition: { duration: 2 }
  }
};